import React from 'react'
import snarkdown from 'snarkdown'

import donate from '../cms/donate'

function Input({ label, hint, type = 'text', value, setValue }) {
    const id = label.toLowerCase().replace(/[^a-z0-9]/g, '')
    return (
        <div className="space-y-1">
            <label htmlFor={id}>
                <p className="text-green-100 font-medium">{label}</p>
                {hint && <p className="text-green-100 italic">{hint}</p>}
            </label>
            <input
                id={id}
                type={type}
                className="block rounded border-none w-full focus:ring-0"
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>
    )
}

export default function DonationBox() {
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [amount, setAmount] = React.useState('')

    const [organizations, setOrganizations] = React.useState((() => {
        const temp = {}
        donate.donation_box_organizations.forEach(o => temp[o] = false)
        return temp
    })())
    const submittedAtKey = 'onecello:donation-box-submitted-at'
    const dataKey = 'onecello:donation-box-data'
    const [submittedAt, setSubmittedAt] = React.useState(typeof window === 'undefined' ? 0 : localStorage.getItem(submittedAtKey))

    const onSubmit = e => {
        e.preventDefault()
        if(isDisabled()) {
            alert('Please fill in all the fields.')
            return
        }

        let checkedOrganizations = []
        for(const name in organizations) {
            if(organizations[name]) checkedOrganizations.push(name)
        }

        fetch('https://friede.gg/api/sheets/append', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: '1lcjEbH3VWEvaS2kznPl02bNR2ckd9GI1QSIMPiB9SBs',
                sheet: 'Donations',
                rows: [{
                    Timestamp: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }),
                    Name: name,
                    Email: email,
                    Organizations: checkedOrganizations.join(', '),
                    'Donated online': amount
                }]
            })
        })
            .then(res => res.json())
            .then(json => {
                if(!json.success) {
                    alert('Something went wrong and your data was not submitted properly.')
                }
            })

        const value = new Date().getTime()
        setSubmittedAt(value)
        localStorage.setItem(submittedAtKey, value)
        localStorage.setItem(dataKey, JSON.stringify({
            amount,
            organizations: checkedOrganizations
        }))
    }

    const isDisabled = () => {
        if(name.trim() === '') return true
        if(email.trim() === '') return true
        if(Object.values(organizations).every(o => !o)) return true
        return false
    }

    const reset = () => {
        localStorage.removeItem(submittedAtKey)
        window.location.reload()
    }

    return (
        <div className="bg-background-dark-secondary shadow-lg rounded-lg p-4 pb-6 max-w-prose">
            <p className="text-green-100 font-bold uppercase mb-4">{donate.donation_box_title}</p>

            {new Date().getTime() - submittedAt < 1000 * 60 * 60 * 24 ? (
                <div className="space-y-3">
                    <p className="text-white">
                        Step 2: Upon completion of this step, your ${JSON.parse(localStorage.getItem(dataKey)).amount} will go to (or be split evenly between) these organizations:

                        <ul className="list-disc ml-8">
                            {JSON.parse(localStorage.getItem(dataKey)).organizations.map(org => (
                                <li className="text-white">{org}</li>
                            ))}
                        </ul>
                    </p>

                    <p className="text-white">
                        Please use one of the following payment methods to make your donation:
                    </p>

                    <ul class="ml-8 list-disc space-y-3">
                        <li className="text-white">
                            Donate through <strong className="text-white">PayPal</strong> (click <a href="https://paypal.me/onecello" className="underline" target="_blank" rel="noreferrer">here</a>, choose <strong className="text-white">“Send”</strong>, and make your payment).
                        </li>

                        <li className="text-white">
                            Donate by <strong className="text-white">check</strong>, using one of the options below:

                            <ul className="list-disc ml-8">
                                <li className="text-white">Make out one check for each organization you want to support, or</li>
                                <li className="text-white">Make out a single check to <strong className="text-white">One Cello, One Planet</strong> and indicate which organizations you want to support.</li>
                            </ul>

                            Send checks to: <strong className="text-white">One Cello, One Planet</strong>, 2 Aerial St., Lexington, MA 02421
                        </li>
                    </ul>

                    <p className="text-white">
                        Thank you!
                    </p>

                    <p className="text-white">If you’d like to make another donation, <button onClick={reset} className="underline">click here</button>.</p>
                </div>
            ) : (
                <>
                    <div
                        className="prose prose-white"
                        dangerouslySetInnerHTML={{ __html: snarkdown(donate.donation_box_description) }}
                    />

                    <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                        <Input label="Your full name" value={name} setValue={setName} />
                        <Input label="Email address" type="email" value={email} setValue={setEmail} />
                        <Input label="Donation Amount ($)" type="number" value={amount} setValue={setAmount} />

                        <div className="space-y-1">
                            <p className="mb-2 text-green-100 font-semibold">Split my donation evenly between the organizations I select below:</p>
                            {donate.donation_box_organizations.map((name, i) => (
                                <div className="flex items-start space-x-3" key={name}>
                                    <input
                                        type="checkbox"
                                        id={'organization-' + i}
                                        className="mt-1 rounded border-none text-blue-600 transition-colors duration-100"
                                        checked={organizations[name]}
                                        onChange={() => {
                                            const temp = JSON.parse(JSON.stringify(organizations))
                                            temp[name] = !temp[name]
                                            setOrganizations(temp)
                                        }}
                                    />
                                    <label htmlFor={'organization-' + i} className="text-white">{name}</label>
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-end">
                            <button
                                className={`${isDisabled() ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'} bg-blue-600 text-white px-4 py-1 rounded font-semibold transition-opacity duration-200`}
                            >
                                Next&nbsp;step&nbsp;&rarr;
                            </button>
                        </div>
                    </form>
                </>
            )}
        </div>
    )
}
