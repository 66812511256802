import React from "react"

import Layout from "../components/layout/"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import Markdown from "../components/Markdown"
import DonationBox from "../components/DonationBox"

import donate from "../cms/donate"

export default () => (
  <Layout>
    <Head title="Donate" />

    <PageHeading text="Donate" />

    {donate.donation_box_enabled && (
        <>
            <DonationBox />
            <div className="mb-16" />
        </>
    )}

    <Markdown
      raw={donate.text}
    />

  </Layout>
)
